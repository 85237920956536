import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import withLayout from "../layout";
import SEO from "../components/seo";

const NotFoundPage = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "error.error-404-title" });

    return (
        <div>
            <SEO
                title={titleText}
                keywords={[
                    "JavaScript",
                    "D3.js",
                    "Software Engineer",
                    "Web Performance",
                ]}
                lang={props.pageContext.locale}
            />
            <div className="container">
                <h1>
                    <FormattedMessage id="error.error-404" />
                </h1>
                <p>
                    <FormattedMessage id="error.error-404-explanation" />
                </p>
            </div>
        </div>
    );
};

const customProps = {
    localeKey: "404",
};

export default withLayout(customProps)(injectIntl(NotFoundPage));
